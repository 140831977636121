<template>
  <div class="account">
    <div class="account-title">
      <div class="fs-20 fw-b dp-f ai-c">账户管理
        <el-image class="w-20 h-20 ml-10 cu-p" @click="isHide = true" v-if="!isHide"
          :src="require('@/assets/img/home/eyeClose.png')"></el-image>
        <el-image class="w-20 h-20 ml-10 cu-p" @click="isHide = false" v-else
          :src="require('@/assets/img/home/eyeOpen.png')"></el-image>
      </div>
      <oabutton CSStype=7 title="套餐" width=80 height=29 @buttonclick="router_push_name('account_center',{accountType:1})"></oabutton>
    </div>
    <div class="database mt-50">
      <template v-for="(item, index) in state.counts" :key="index">
        <div class="database-item">
          <div class="fs-38 fw-b mb-15" v-if="isHide">{{ item.value }}</div>
          <div class="fs-38 fw-b mb-15" v-else>***</div>
          <div class="fs-16 c-666">{{ item.label }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { router_push_name } from "@/utils/server/router";
import { httpToken } from "@/utils/request";
import { convert } from "@/utils/util"
import qs from "qs";
const countLoading = ref(false);//loading 显示
const state = reactive({
  counts: [
    {
      label: "当前剩余次数(次)",
      value: 0
    },
    {
      label: "总消费额(元)",
      value: 0
    },
    {
      label: "已使用次数",
      value: 0
    },
  ]
})
let isHide = ref(false)
// 获取账户信息
const getAccount = () => {
  countLoading.value = true
  httpToken({
    method: "post",
    url: '/admin/account/getAccount',
  }).then(res => {
    state.counts[0].value = res.data.remainingAmount
    state.counts[1].value = convert(res.data.payAmount,false)
    state.counts[2].value = res.data.usedAmount
    countLoading.value = false;
  }).catch((err) => {
    countLoading.value = false;
  })
}
getAccount()
</script>

<style lang="scss" scoped>
.account {
  width: calc(50% - 6px);
  background-color: #fff;

  &-title {
    margin: 19px 30px 0;
    display: flex;
    justify-content: space-between;

    .btn {
      border: 1px solid #3377E7;
      padding: 4px 24px;
      color: #3377E7;
    }
  }

  .database {
    display: flex;
    justify-content: space-around;
    padding: 0 51px;

    &-item {
      text-align: center;
    }
  }
}</style>
